import React, { useEffect, useState } from 'react';
import OrganisationForm from './OrganisationForm';
import OrganisationDetailsModal from './OrganisationDetailsModal';
import { fetchOrganisationlist, fetchPrices } from './utils/fetch';
import { useNavigate } from 'react-router-dom';
import './Organisation.css'; // Import CSS file for component styling

const OrganisationList = () => {
    const [productsList, setProductsList] = useState([]);
    const [orgDetails, setOrgDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        fetchOrganisationlist(navigate)
            .then(items => {
                const organisationData = Object.values(items).map(item => {
                    const orgDetails_value = {
                        Org_ID: item.org_id,
                        Org_Name: item.name,
                        Mask: item.mask,
                        Logo: item.logo,
                        GreenMask: item.greenmask,
                        Theme_Color: item.theme_color,
                        Font: item.font,
                        Favicon: item.favicon
                    };
    
                    const landingpage = item.landingpage && Array.isArray(item.landingpage)
                        ? item.landingpage.map(sampleproduct => ({
                            SampleProduct_Name: sampleproduct.name,
                            SampleProduct_Asset_Front: sampleproduct.asset,
                            SampleProduct_Asset_Back: sampleproduct.asset_back
                        }))
                        : [];
    
                    const products = item.products && Array.isArray(item.products)
                        ? item.products.map(product => ({
                            Product_Name: product.name,
                            Product_Description: product.description,
                            Product_Default_Color: product.default_color,
                            Product_Sizes: product.sizes,
                            Product_Position: product.position,
                            Product_Mask: product.mask,
                            Product_Clip: product.clip,
                            Product_Colors: product.colors,
                            Product_DefaultProduct: product.defaultProduct,
                            Product_Greenmask: product.greenmask,
                            Product_Dimensions_Top: product.dimensions.top,
                            Product_Dimensions_Left: product.dimensions.left,
                            Product_Dimensions_Height: product.dimensions.height,
                            Product_Dimensions_Width: product.dimensions.width
                        }))
                        : [];

                    const secrets = item.secrets ? item.secrets : {
                        "STRIPE_CHECKOUT_ENABLED": true,
                        "SIDEBAR_PRODUCT_IMAGES_ENABLED": true,
                        "CART_ENABLED": true,
                        "SHOWPRICE": true,
                        "SAMPLE_PRODUCT_SLIDER": true
                    }
    
                    return { ...orgDetails_value, LandingPage: landingpage, Products: products, secrets: secrets };
                });
    
                setOrgDetails(organisationData);
                setLoading(false);
                console.log("organisation List from organisationData:", organisationData);
            })
            .catch(() => setLoading(false));
    }, [navigate]);

    const [organisations, setOrganisations] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOrganisation, setSelectedOrganisation] = useState(null);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

    const addOrganisation = (newOrganisation) => {
        setOrganisations([...organisations, newOrganisation]);
        // setIsModalOpen(false);
    };

    const showOrganisationDetails = (organisation) => {
        setSelectedOrganisation(organisation);
        console.log(organisation)
        setIsDetailsModalOpen(true);
    };

    return (
        <div className="organisation-list-container" style={{ marginTop: '4%' }}>
            <button className="add-organisation-button" onClick={() => setIsModalOpen(true)}>
                Add New Organisation
            </button>
            {loading ? (
                <div className="loader-container">
                <div className="loader-text">Fetching Organisations...</div>
            </div>
            ) : (
                <table className="organisation-table">
                    <thead>
                        <tr>
                            <th>Organisation Name</th>
                    </tr>
                </thead>
                <tbody>
                    {orgDetails.map((org, index) => (
                        <tr key={index}>
                            <td>
                                <button className="organisation-link" onClick={() => showOrganisationDetails(org)}>
                                    {org.Org_Name}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>)}

            {isModalOpen && (
                <div className="modal">
                    <span className="close-button" onClick={() => setIsModalOpen(false)}>
                            &times;
                        </span>
                    <div className="modal-content">
                        <OrganisationForm addOrganisation={addOrganisation} />
                    </div>
                </div>
            )}

            {isDetailsModalOpen && selectedOrganisation && (
                <div className="modal">
                <span className="close-button" onClick={() => setIsDetailsModalOpen(false)}>
                    &times;
                </span>
                    <div className="modal-content">
                        <OrganisationDetailsModal organisation={selectedOrganisation} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrganisationList;
