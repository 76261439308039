import axiosInstance from '../axiosInstance';

export const fetchOrganisationlist = async (navigate) => {
    try {
        const response = await axiosInstance.post("/organisation_list");
        const data = response.data;
        if (response.status !== 200) throw new Error('Failed to fetch Organisations');
        return data;
    } catch (err) {
        return handleHttpError(err, navigate, 'fetchOrganisationlist')
    }
};
export const fetch_DownloadStudentVerifiedOrders = async (password,mode,order_ids,task_id) => {
    try {
        task_id = task_id.toString()
        const response = await axiosInstance.post("/download_student_verified_orders", { 
            password, mode, order_ids, task_id
        }, { responseType: 'blob' });
        if (response.status !== 200) throw new Error('Failed to download zip');

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'student_products.zip';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        return response
    } catch (err) {
        console.log(err)
        return err.response.status
    }
};
export const fetch_DownloadSelectedPreparedOrders = async(order_ids) => {
    try {
        const response = await axiosInstance.post("/bulk-download-prepared_orders", order_ids, { responseType: 'blob' });
        if (response.status !== 200) throw new Error('Failed to download prepared orders');
        
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'prepared_orders.zip';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        return response
    } catch (err) {
        console.log(err)
        return err.response.status
    }
}
export const fetchAddtoOrganisation = async (item, navigate) => {
    try {
        const response = await axiosInstance.post('/create_organisation', item);
        if (response.status !== 200) throw new Error('Failed to Create Organisation');
        return {success: true};
    } catch (err) {
        return handleHttpError(err, navigate, 'fetchAddtoOrganisation')
    }
};

export const fetchPrices = async () => {
    try {
        const response = await axiosInstance.post('/get_prices');
        if (response.status !== 200) throw new Error('Failed to get prices');
            return response.data;
    } catch (err) {
        return 0;
    }
};

export const bulkorder = async (formData) => {
    try {
      const response = await axiosInstance.post('/bulk-order', formData);
      if (response.status !== 200) throw new Error('Failed to upload file');
      return response.data;
    } catch (err) {
      return err.response.status;
    }
  };

export const get_org = async (payload) => {
    try {
      const response = await axiosInstance.post('/get_org_data', payload);
      if (response.status !== 200) throw new Error('Failed to upload file');
      return response.data;
    } catch (err) {
      return err.response.status;
    }
  };

  export const get_order_by_id = async (order_id) => {
    try {
      const response = await axiosInstance.post('/get_order_by_id', order_id);
      if (response.status !== 200) throw new Error('Failed to fetch order');
      return response.data;
    } catch (err) {
      return err.response.status;
    }
  };

  export const delete_org = async (payload) => {
    try {
      const response = await axiosInstance.post('/delete_organisation', payload);
      if (response.status !== 200) throw new Error('Failed to Delete Organisation');
      return response.data;
    } catch (err) {
      return err.response.status;
    }
  };

export const get_three_images = async (prompt) =>{
    try {
        const response = await axiosInstance.post("/generate_three_image", prompt);
        if(response.status !== 200){
            return response.status
        }
        return response.data
    } catch (err) {
        return err.response.status;
      }
}
export const reorder = async (file) => {
    try {
        const response = await axiosInstance.post("/regenerate_order",file);
        if(response.status !== 200){
            return response.status
        }
        return response.data
    } catch (err) {
        return err.response.status;
      }
}
export const updateOrg = async (org_id, item, navigate) => {
    // Transform the item data to match the required structure
    let transformedOrganisation = {
        org_id: item.Org_ID,
        name: item.Org_Name,
        mask: item.Mask,
        logo: item.Logo,
        greenmask: item.GreenMask,
        theme_color: item.Theme_Color,
        font: item.Font,
        favicon: item.Favicon,
        landingpage: [],
        products: [],
        secrets: item.secrets
    };

    item.LandingPage.forEach((landingpage, index) => {
        let transformedlandingpage = {
            name: landingpage.SampleProduct_Name,
            asset: landingpage.SampleProduct_Asset_Front,
            asset_back: landingpage.SampleProduct_Asset_Back
        };
        transformedOrganisation.landingpage.push(transformedlandingpage);
    });

    // Transform each product
    item.Products.forEach((product, index) => {
        let transformedProduct = {
            name: product.Product_Name,
            description: product.Product_Description,
            default_color: product.Product_Default_Color,
            clip: product.Product_Clip,
            sizes: product.Product_Sizes,
            mask: product.Product_Mask,
            position: product.Product_Position,
            colors: {}
        };

        // Transform the colors
        Object.keys(product.Product_Colors).forEach((key, colorIndex) => {
            let color = product.Product_Colors[key];
            transformedProduct.colors[key] = {
                name: color.name,
                asset: color.asset,
                color_map: color.color_map
            };
        });

        transformedProduct.defaultProduct = product.Product_DefaultProduct;
        transformedProduct.greenmask = product.Product_Greenmask;
        transformedProduct.dimensions = {
            top: product.Product_Dimensions_Top,
            left: product.Product_Dimensions_Left,
            height: product.Product_Dimensions_Height,
            width: product.Product_Dimensions_Width
        };

        transformedOrganisation.products.push(transformedProduct);
    });

    // Make the POST request with Axios
    try {
        const response = await axiosInstance.post(`/update_organisation`, transformedOrganisation);
        if (response.status !== 200) throw new Error('Failed to Update Organisation');
        return { success: true };
    } catch (err) {
        return handleHttpError(err, navigate, 'updateOrg');
    }
};


function handleHttpError(err, navigate, funcName) {
    if (err.response) {
        const status = err.response.status;
        const _message = (err.response.data && err.response.data && err.response.data.detail && (typeof err.response.data.detail == "string"))
         ?  err.response.data.detail : (err.response.data.detail && err.response.data.detail.message) 
         ? err.response.data.detail.message : err.response.data.toString();
        
        switch (status) {
            case 400:
                return { success: false, message: _message, navigated: false }; // Indicate no navigation
            case 401:
                navigate('/auth')
                return { success: false, navigated: true }; // Indicate navigation
            case 422:
                return { success: false, message: "Your input is unprocessible.", navigated: false }; // Indicate no navigation
            case 429:
                navigate(`/error?message=${encodeURIComponent("Rate Limit Exceeded")}&source=${encodeURIComponent(funcName)}`);
                return { success: false, navigated: true }; // Indicate navigation
            default:
                // const message = err.response && err.response.data ? err.response.data.detail : err.detail;
                navigate(`/error?message=${encodeURIComponent(_message)}&source=${encodeURIComponent(funcName)}`);
                return { success: false, navigated: true }; // Indicate navigation
        }
    }
    return { success: false, navigated: false }; // Indicate no navigation if no response
}